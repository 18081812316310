import { motion } from "framer-motion";
import { useState } from "react";

export default function DemoCard({ data, style }) {
  const [imageHeight, setImageHeight] = useState(100);
  const [contentHeight, setContentHeight] = useState(0);
  return (
    <>
      {/* Desktop View */}
      <motion.div
        style={style}
        className="border border-gray-400 shadow-xl rounded-2xl h-[31vw] text-start relative hidden lg:block overflow-hidden flex-shrink-0"
        tabIndex="0"
        onFocus={() => {
          setImageHeight(80);
          setContentHeight(21);
        }}
        onBlur={() => {
          setImageHeight(100);
          setContentHeight(0);
        }}
      >
        <motion.img
          animate={{ height: `${imageHeight}%` }}
          whileHover={{ height: `80%` }}
          onHoverStart={() => setContentHeight(21)}
          onHoverEnd={() => setContentHeight(0)}
          src={data.image}
          alt="Preview"
          className="w-full"
        />
        <motion.div
          animate={{ height: `${contentHeight}%` }}
          onHoverStart={() => setImageHeight(80)}
          onHoverEnd={() => setImageHeight(100)}
          whileHover={{ height: "21%" }}
          className="border-t-2 py-[4vh] lg:py-[1vw] lg:px-[0.9vw] absolute bottom-0 left-0 right-0 bg-white"
        >
          <h1 className="text-[2vh] lg:text-[1.3vw] mb-[1%] font-bold">
            {data.title}
          </h1>
          <p className="text-[1.7vh] md:text-[0.9]">{data.subtitle}</p>
        </motion.div>
      </motion.div>
      {/* Mobile View */}
      <div className="rounded-2xl text-start lg:hidden my-[4vh] overflow-hidden shadow-xl">
        <img src={data.image} alt="Preview" className="w-full" />
        <div className="border-t-2 px-[0.9vw] py-[2vh] bg-white">
          <h1 className="text-[2vh] mb-[1%] font-bold">{data.title}</h1>
          <p className="text-[1.7vh] lg:text-[0.9vh]">{data.subtitle}</p>
        </div>
      </div>
    </>
  );
}
