"use client";

import { useLanguage, useTranslation } from "@/Utils/lanHook";
import Image from "next/image";
import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FiMenu, FiX } from "react-icons/fi";

const navLinks = [
  { id: "section1", label: "nav.landingLinks.home" },
  { id: "section2", label: "nav.landingLinks.home" },
  { id: "section3", label: "nav.landingLinks.about" },
  { id: "section4", label: "nav.landingLinks.contact" },
  { id: "section5", label: "nav.landingLinks.blog" },
  { id: "section6", label: "nav.landingLinks.shop" },
];

export default function LandingNavBar() {
  const [activeLink, setActiveLink] = useState(navLinks[0].id);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const { language, setReduxLanguage } = useLanguage();

  const handleLangToggle = () => {
    const newLanguage = language === "ar" ? "en" : "ar";
    setReduxLanguage(newLanguage);
    console.log(`Current language: ${language}`);
    document.location.replace("/");
  };

  const handleLinksCLick = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
    setActiveLink(id);
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveLink(entry.target.id);
        }
      });
    }, observerOptions);

    navLinks.forEach((link) => {
      const section = document.getElementById(link.id);
      if (section) observer.observe(section);
    });

    return () => {
      // Cleanup observer
      observer.disconnect();
    };
  }, []);

  return (
    <>
      {/* Desktop Navbar */}
      <motion.nav
        initial={{ backgroundColor: "rgba(72, 128, 143, 0.1)" }}
        animate={{
          backgroundColor:
            activeLink === navLinks[0].id
              ? "rgba(72, 128, 143, 0.1)"
              : "rgba(72, 128, 143, 0.6)",
        }}
        transition={{ duration: 0.5 }}
        className={`hidden lg:flex fixed top-0 w-full z-50 justify-between items-center py-[0.7vw] px-[0.6vw] text-[1.2vw] backdrop-blur-sm ${
          activeLink === navLinks[0].id ? "bg-primary/20" : "bg-primary/50"
        }`}
      >
        <div className="w-1/2 flex justify-between items-center">
          <span className="w-[6vw]">
            <Image
              className="object-contain"
              src="Logo.png"
              alt="logo"
              width={1920}
              height={1080}
            />
          </span>
          <ul className="w-3/4 flex justify-between items-center text-nowrap">
            {navLinks.map((link) => (
              <li
                key={link.id}
                className={`cursor-pointer py-[0.25vw] px-[0.75vw] ${
                  activeLink === link.id
                    ? "bg-[#f9f9f9] text-primary rounded-md"
                    : "text-white"
                }`}
                onClick={() => handleLinksCLick(link.id)}
              >
                {t(link.label)}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-between items-center gap-[0.8vw]">
          <div
            onClick={handleLangToggle}
            className="flex-shrink-0 cursor-pointer w-[1vw] h-[1vw] p-[1.2vw] bg-[#f9f9f9] rounded-full flex items-center justify-center"
          >
            <div className="text-black text-[0.98vw] font-normal">
              {language.toUpperCase()}
            </div>
          </div>
          <span className="bg-[#f9f9f9] text-primary px-[1.5vw] py-[0.25vw] rounded-md text-nowrap cursor-pointer">
            {t("nav.landingLinks.join")}
          </span>
        </div>
      </motion.nav>

      {/* Mobile Navbar */}
      <div
        className={`lg:hidden fixed top-0 w-full z-50 flex justify-between items-center p-[1.5vw] backdrop-blur-sm ${
          activeLink === navLinks[0].id ? "bg-primary/20" : "bg-primary/50"
        }`}
      >
        <Image
          className="object-contain w-[20%]"
          src="Logo.png"
          alt="logo"
          width={1920}
          height={1080}
        />
        <div className="flex gap-[1vh] items-center">
          <div className="flex justify-between items-center gap-[1vh]">
            <div
              onClick={handleLangToggle}
              className="flex-shrink-0 cursor-pointer w-[4vh] h-[4vh] p-[1.5vh] bg-[#f9f9f9] rounded-full flex items-center justify-center"
            >
              <div className="text-black text-[1.5vh] font-normal">
                {language.toUpperCase()}
              </div>
            </div>
            <span className="bg-[#f9f9f9] text-primary px-[4vh] py-[0.7vh] rounded-md text-nowrap text-[1.5vh]">
              {t("nav.landingLinks.join")}
            </span>
          </div>
          <span
            onClick={() => setIsMenuOpen(true)}
            className="text-primary cursor-pointer bg-white/90 p-[1vh] rounded-full"
          >
            <FiMenu className="text-[2vh] cursor-pointer " />
          </span>
        </div>
      </div>

      {/* Overlay Menu */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ x: language === "en" ? "100%" : "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: language === "en" ? "100%" : "-100%" }}
            transition={{ stiffness: 100 }}
            className={`lg:hidden fixed top-0 w-full h-full z-50 text-white flex backdrop-blur-2xl bg-white/20 ${
              language === "en" ? "justify-end" : "justify-start"
            }`}
          >
            <div className="h-[90%] w-[85%] pt-[15%] flex flex-col items-center justify-start bg-gradient-to-b from-primary/60 to-primary/0">
              <span
                onClick={() => setIsMenuOpen(false)}
                className={`text-[2vh] absolute top-[2vh] text-primary ${
                  language === "en" ? "right-[2vh]" : "left-[2vh]"
                } cursor-pointer bg-white/90 p-[1vh] rounded-full`}
              >
                <FiX />
              </span>
              <img src="/Logo.png" alt="" className="w-[40%] md:w-[30%] mb-5" />
              <ul className="space-y-4 text-lg">
                {navLinks.map((link) => (
                  <li
                    key={link.id}
                    className="cursor-pointer"
                    onClick={() => {
                      handleLinksCLick(link.id);
                      setIsMenuOpen(false);
                    }}
                  >
                    {t(link.label)}
                  </li>
                ))}
              </ul>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
