import { useTranslation } from "@/Utils/lanHook";
import Image from "next/image";
import React from "react";

export default function LandingFooter() {
  const socialLinks = [
    {
      name: "LinkedIn",
      icon: "https://cdn-icons-png.flaticon.com/512/174/174857.png",
      link: "https://www.linkedin.com/in/abdulhakeem-baig-6b4b8b1b4/",
    },
    {
      name: "Instagram",
      icon: "https://cdn-icons-png.flaticon.com/512/174/174855.png",
      link: "https://www.instagram.com/abdulhakeembaig/",
    },
    {
      name: "facebook",
      icon: "https://cdn-icons-png.flaticon.com/512/174/174848.png",
      link: "https://www.facebook.com/abdulhakeembaig",
    },
  ];
  const pagesLinks = [
    {
      id: 1,
      title: "Privacy Policy",
      link: "https://www.linkedin.com/in/abdulhakeem-baig-6b4b8b1b4/",
    },
    {
      id: 2,
      title: "Terms",
      link: "https://www.linkedin.com/in/abdulhakeem-baig-6b4b8b1b4/",
    },
  ];
  const { t } = useTranslation();
  return (
    <footer className="border-t-2 mt-[3vh] lg:mt-[3vw] rounded-3xl lg:rounded-[2vw]">
      <div className="flex flex-col lg:flex-row items-center my-[2vh] lg:my-[1.5vw] lg:px-[1.5vw]">
        <span className="w-1/2 mb-[2vh] lg:mb-0">
          <Image
            className="w-[40%] lg:w-[15%] mx-auto lg:mx-0"
            src="Logo.png"
            alt="logo"
            width={1920}
            height={1080}
          />
        </span>
        <div className="font-bold text-center w-1/2 flex flex-col lg:items-end">
          <div>
            <h1>{t("landingContent.footer_text")}</h1>
            <div className="flex justify-center gap-[2vh] lg:gap-[1vw] mt-[1vh] lg:mt-[0.8vw]">
              {socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Image
                    src={link.icon}
                    alt={link.name}
                    width={1920}
                    height={1080}
                    className="w-[4vh] lg:w-[2.5vw]"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#F7FAFA] py-[1vh] lg:py-[0.5vw] px-[2vh] lg:px-[1.5vw] lg:flex justify-between text-center text-[1.5vh] lg:text-[0.8vw]">
        <div className="flex items-center justify-center lg:justify-start gap-[2vh] lg:gap-x-[1vw] mb-[2vh] lg:mb-0">
          {pagesLinks.map((link, index) => (
            <a
              key={index}
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.title}
            </a>
          ))}
        </div>
        <h1 className="text-nowrap">
          &copy; 2024 {t("landingContent.copyright")}
        </h1>
      </div>
    </footer>
  );
}
