import { useLanguage } from "@/Utils/lanHook";
import { motion } from "framer-motion";

export default function Marequee({ items = [] }) {
  const { language } = useLanguage();
  return (
    <div className="w-full overflow-hidden py-[2%]">
      <div className="flex">
        <motion.div
          initial={{ x: 0 }}
          transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          animate={{ x: language === "ar" ? "100%" : "-100%" }}
          className="flex flex-shrink-0"
        >
          {items.map((item, index) => {
            return (
              <img
                className="pr-[2vh] lg:pr-[2vw] h-[3vh] lg:h-[3vw]"
                key={index}
                src={item}
              />
            );
          })}
        </motion.div>
        <motion.div
          initial={{ x: 0 }}
          transition={{ duration: 20, repeat: Infinity, ease: "linear" }}
          animate={{ x: language === "ar" ? "100%" : "-100%" }}
          className="flex flex-shrink-0"
        >
          {items.map((item, index) => {
            return (
              <img
                className="pr-[2vh] lg:pr-[2vw] h-[3vh] lg:h-[3vw]"
                key={index}
                src={item}
              />
            );
          })}
        </motion.div>
      </div>
    </div>
  );
}
