export default function EmonialCard({ data }) {
  return (
    <div className="text-start border border-gray-300 shadow-xl rounded-2xl p-[2vw] w-[100%] md:w-[48%]">
      <h1 className="text-primary text-[2vh] md:text-[1vw] mb-[1%]">
        {data.state}
      </h1>
      <p className="text-[1vh] md:text-[0.8vw] mb-[1%]">{data.rate}</p>
      <div className="flex gap-[1vh] lg:gap-[0.5vw] items-center">
        <img src={data.image} alt="emonial" />
        <h3 className="text-[1.5vh] lg:text-[0.8vw]">{data.name}</h3>
      </div>
    </div>
  );
}
