import { currencyFormat } from "@/Utils/commons";
import { useTranslation } from "@/Utils/lanHook";
import React from "react";

export default function PackagesCard({ singlePackage }) {
  const { t } = useTranslation();
  return (
    <div className="w-[95%] md:w-[70%] lg:w-[30%] flex-shrink-0">
      <h1>{singlePackage.name}</h1>
      <p className="text-gray-400 text-[2vh] lg:text-[0.8vw] my-2">
        {t("landingContent.only")}
      </p>
      <h1 className="text-[3vh] lg:text-[2vw]">
        {" "}
        <span className="text-[2vh] lg:text-[0.8vw]">
          {currencyFormat()}
        </span>{" "}
        {singlePackage.price}
        <span className="text-[2vh] lg:text-[0.8vw]">
          /{singlePackage.type}
        </span>
      </h1>
      <h1 className="my-2 lg:my-5 lg:text-[0.8vw]">
        {t("landingContent.available_featchers")}
      </h1>
      {singlePackage.available_features.map((feature, index) => (
        <div key={index} className="flex items-center my-2 gap-4">
          <img src="/checkFeatcher.svg" alt="" className="w-[2vh]" />
          <p className="text-[2vh] lg:text-[0.8vw]">{feature}</p>
        </div>
      ))}
      <button
        className={`hover:bg-primary hover:text-white focus:bg-primary focus:text-white bg-primary/10 text-primary transition duration-300 ease-in-out p-[1.5vw] lg:p-[1.5vh] rounded-md lg:rounded-xl mt-[8%] w-[95%] md:w-[75%] lg:w-[55%] block`}
        type="button"
      >
        {t("landingContent.get_started")}
      </button>
    </div>
  );
}
