"use client";

import DemoCard from "@/components/demoCard";
import EmonialCard from "@/components/emonialCard";
import KeyFeatchers from "@/components/keyFeatchers";
import LandingFooter from "@/components/landingFooter";
import LandingNavBar from "@/components/landingNavBar";
import Marquee from "@/components/marequee";
import PackagesCard from "@/components/packagesCard";
import ProjectShowButtons from "@/components/projectShow";
import { hexToRgb, primaryColor } from "@/Utils/appColors";
import { useLanguage, useTranslation } from "@/Utils/lanHook";
import { handleDragScroll, handleScroll } from "@/Utils/scrollUtils";
import { AnimatePresence, motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
export default function LandingPage() {
  const images = [
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
    "/tesmonial.svg",
  ];
  const packagesData = [
    {
      id: 1,
      name: "profissional package",
      price: "80",
      type: "month",
      available_features: [
        "all features from basick package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 2,
      name: "Basic Package",
      price: "99",
      type: "month",
      available_features: [
        "Daily order management",
        "Basic performance reports",
        "Simple interface customization",
        "Email support",
      ],
    },
    {
      id: 3,
      name: "Simple Package",
      price: "80",
      type: "month",
      available_features: [
        "All features from the Basic Package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 4,
      name: "profissional package",
      price: "80",
      type: "month",
      available_features: [
        "all features from basick package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 5,
      name: "Basic Package",
      price: "99",
      type: "month",
      available_features: [
        "Daily order management",
        "Basic performance reports",
        "Simple interface customization",
        "Email support",
      ],
    },
    {
      id: 6,
      name: "Simple Package",
      price: "80",
      type: "month",
      available_features: [
        "All features from the Basic Package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 7,
      name: "profissional package",
      price: "80",
      type: "month",
      available_features: [
        "all features from basick package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 8,
      name: "Basic Package",
      price: "99",
      type: "month",
      available_features: [
        "Daily order management",
        "Basic performance reports",
        "Simple interface customization",
        "Email support",
      ],
    },
    {
      id: 9,
      name: "Simple Package",
      price: "80",
      type: "month",
      available_features: [
        "All features from the Basic Package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 10,
      name: "profissional package",
      price: "80",
      type: "month",
      available_features: [
        "all features from basick package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
    {
      id: 11,
      name: "Basic Package",
      price: "99",
      type: "month",
      available_features: [
        "Daily order management",
        "Basic performance reports",
        "Simple interface customization",
        "Email support",
      ],
    },
    {
      id: 12,
      name: "Simple Package",
      price: "80",
      type: "month",
      available_features: [
        "All features from the Basic Package",
        "Detailed analytical reports",
        "Marketing tools to attract customers",
        "Payment integration wit applications",
      ],
    },
  ];
  const demoContent = [
    {
      id: 1,
      title: "View the right community data",
      subtitle:
        "Customize the view of your dashboards and vizualize the right community data.",
      image: "/lapFrame.png",
    },
    {
      id: 2,
      title: "View the right community data",
      subtitle:
        "Customize the view of your dashboards and vizualize the right community data.",
      image: "/lapFrame.png",
    },
    {
      id: 3,
      title: "View the right community data",
      subtitle:
        "Customize the view of your dashboards and vizualize the right community data.",
      image: "/lapFrame.png",
    },
    {
      id: 4,
      title: "View the right community data",
      subtitle:
        "Customize the view of your dashboards and vizualize the right community data.",
      image: "/lapFrame.png",
    },
  ];
  const emonialStatus = [
    {
      id: 1,
      state: "Happy!",
      name: "Name Here",
      rate: "I have been extremely happy with the results of working with the creative agency, and I would highly recommend them to anyone who is looking for high-quality design and development services.",
      image: "monialSvg.svg",
    },
    {
      id: 2,
      state: "Happy!",
      name: "Name Here",
      rate: "I have been extremely happy with the results of working with the creative agency, and I would highly recommend them to anyone who is looking for high-quality design and development services.",
      image: "monialSvg.svg",
    },
    {
      id: 3,
      state: "Happy!",
      name: "Name Here",
      rate: "I have been extremely happy with the results of working with the creative agency, and I would highly recommend them to anyone who is looking for high-quality design and development services.",
      image: "monialSvg.svg",
    },
    {
      id: 4,
      state: "Happy!",
      name: "Name Here",
      rate: "I have been extremely happy with the results of working with the creative agency, and I would highly recommend them to anyone who is looking for high-quality design and development services.",
      image: "monialSvg.svg",
    },
    {
      id: 5,
      state: "Happy!",
      name: "Name Here",
      rate: "I have been extremely happy with the results of working with the creative agency, and I would highly recommend them to anyone who is looking for high-quality design and development services.",
      image: "monialSvg.svg",
    },
  ];
  const buttonsContent = [
    {
      id: 1,
      title: "Order Organization and Leave Scheduling",
      content:
        "Simplify customer order management and employee leave scheduling with an easy-to-use interface",
      image:
        "https://images.unsplash.com/photo-1506773090264-ac0b07293a64?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=800",
    },
    {
      id: 2,
      title: "Inventory Management",
      content:
        "Efficiently manage inventory and track product availability with a user-friendly interface",
      image:
        "https://images.unsplash.com/photo-1731331095592-c86db3fa1d51?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 3,
      title: "Sales and Customer Relationship Management",
      content:
        "Streamline sales and customer relationship management processes with a user-friendly interface",
      image:
        "https://images.unsplash.com/photo-1719937051058-63705ed35502?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 4,
      title: "Human Resource Management",
      content:
        "Efficiently manage human resources and employee information with a user-friendly interface",
      image:
        "https://images.unsplash.com/photo-1731370963535-aa6e57ed40f6?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw0fHx8ZW58MHx8fHx8",
    },
    {
      id: 5,
      title: "Finance and Accounting",
      content:
        "Streamline finance and accounting processes with a user-friendly interface",
      image:
        "https://images.unsplash.com/photo-1731271140119-34ad9551ff10?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw5fHx8ZW58MHx8fHx8",
    },
    {
      id: 6,
      title: "Inventory Management",
      content:
        "Efficiently manage inventory and track product availability with a user-friendly interface",
      image:
        "https://images.unsplash.com/photo-1731484396266-b80443ec385b?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwxMnx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 7,
      title: "Marketing and Strategy",
      content:
        "Plan and execute marketing strategies with innovative tools and data-driven insights",
      image:
        "https://images.unsplash.com/photo-1732823141364-b61b8ff8c490?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyMHx8fGVufDB8fHx8fA%3D%3D",
    },
    {
      id: 8,
      title: "Customer Feedback Analysis",
      content:
        "Analyze customer feedback effectively to improve service quality",
      image:
        "https://images.unsplash.com/photo-1558403194-611308249627?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=800",
    },
  ];
  const [selectedImage, setSelectedImage] = useState(buttonsContent[0].image);
  const handleButtonClick = (image) => {
    setSelectedImage(image);
  };
  const { language } = useLanguage();
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    if (typeof window !== "undefined") {
    }
    if (typeof window !== "undefined") {
      localStorage.removeItem("cart");
      localStorage.removeItem("accountSettings");
      localStorage.removeItem("branch");
      document.documentElement.style.setProperty(
        "--primary-color",
        hexToRgb(primaryColor)
      );
    }
  });

  useEffect(() => {
    const container = scrollContainerRef.current;

    const handleWheelScroll = (e) => handleScroll(e, container);

    container.addEventListener("wheel", handleWheelScroll, {
      passive: false,
    });

    const cleanupDragScroll = handleDragScroll(container);

    return () => {
      container.removeEventListener("wheel", handleWheelScroll);
      cleanupDragScroll();
    };
  }, []);

  const { t } = useTranslation();
  return (
    <main className="items-center text-[2vh] lg:text-[1.25vw] w-screen overflow-hidden">
      <LandingNavBar />
      <section
        id="section1"
        className="bg-gradient-to-b from-primary/40 to-white pt-[5vh] lg:pt-[8vh]"
      >
        <div className="flex flex-col lg:flex-row justify-center items-center pt-[5vh] md:pt-[8vh] lg:pt-[4vw] px-[2vw] w-[94%] lg:w-[97%]">
          <span>
            <h1 className="font-bold text-[2.2vh] lg:text-[2vw] text-center">
              {t("landingContent.headline")}
            </h1>
            <p className="px-[7%] mt-[5%] text-gray-500">
              {t("landingContent.headsubline")}
            </p>
            <button
              className="bg-primary text-white p-[1.5vw] rounded-md mt-[8%] mb-[5%] lg:mb-0 mx-auto block"
              type="button"
            >
              {t("landingContent.demobutton")}
            </button>
          </span>
          <span className="relative w-full">
            <Image
              src="lapFrame.png"
              alt="landing"
              width={1920}
              height={1080}
              className="object-fill w-full"
            />
            <Image
              src="phoneFrame.png"
              alt="landing"
              width={140}
              height={1080}
              className={`object-contain w-[20%] absolute ${
                language === "ar"
                  ? "left-0 -translate-x-[40%]"
                  : "right-0 translate-x-[40%]"
              } top-1/2 -translate-y-1/2 h-1/2`}
            />
          </span>
        </div>
        <div className="mt-[5%]">
          <h1 className="text-[2vh] lg:text-[2vw] text-center font-bold">
            {t("landingContent.leading")}
          </h1>
        </div>
        <Marquee items={images} />
      </section>
      <section id="section2" className="mt-10">
        <div className="justify-self-center items-center px-[2vh] lg:px-[1vw]">
          <h1 className="font-bold text-[2.15vh] lg:text-[2.5vw] mb-6 text-center">
            {t("landingContent.customerTrust")}
          </h1>
          <Image
            src="landing.svg"
            alt=""
            width={1920}
            height={1080}
            className="w-[80vh] lg:w-[60vw] mx-auto"
          />
        </div>
        <div className="px-[2vh] lg:px-[1vw] lg:flex mb-5 lg:mb-0 justify-center items-center mt-[15%] md:mt-[5%] text-center lg:text-start">
          <span className="w-1/2">
            <h1 className="font-bold text-[2.2vh] lg:text-[2vw] mb-4">
              {t("landingContent.smartoffes")}
            </h1>
            <p className="my-7 text-gray-500">
              {t("landingContent.subsmartoffers")}
            </p>
          </span>
          <span className="w-1/2 object-fill">
            <Image
              width={1920}
              height={1080}
              src="landing2.png"
              alt="landing2"
              className="rounded-lg w-full"
            />
          </span>
        </div>
      </section>
      <section id="section3" className="mt-[3%] px-[2vh] lg:px-[1vw]">
        <div>
          <h1 className="font-bold text-[2.2vh] lg:text-[2vw] mb-6 text-center bg-gradient-to-r from-transparent via-primary/10 to-transparent w-1/2 mx-auto">
            {t("landingContent.key")}
          </h1>
          <KeyFeatchers />
        </div>
        <div className="mt-[15%] md:mt-[10%] lg:mt-[5%]">
          <h1 className="font-bold text-[2.2vh] lg:text-[2vw] mb-6 text-center bg-gradient-to-r from-transparent via-primary/10 to-transparent w-1/2 mx-auto">
            {t("landingContent.packages")}
          </h1>
          <div
            ref={scrollContainerRef}
            className="w-full overflow-x-auto scrollbar-hide flex text-nowrap beha"
          >
            {packagesData.map((singlePackage) => (
              <PackagesCard
                key={singlePackage.id}
                singlePackage={singlePackage}
              />
            ))}
          </div>
        </div>
      </section>
      <section
        id="section4"
        className="mt-[15%] md:mt-[10%] lg:mt-[5%] text-center px-[2vh] lg:px-[1vw]"
      >
        <div>
          <h1 className="font-bold text-[2.2vh] lg:text-[2vw] mb-6 text-center bg-gradient-to-r from-transparent via-primary/10 to-transparentm">
            {t("landingContent.control")}
          </h1>
          <p className="my-7 text-gray-500 px-[10%] md:px-[15%] lg:w-1/2 mx-auto lg:px-[5%]">
            {t("landingContent.subcontrol")}
          </p>
        </div>
        <div className="mt-[15%] md:mt-[10%] lg:mt-[5%] lg:flex flex-wrap justify-center lg:gap-[1vw]">
          {demoContent.map((demo, index) => {
            const isOddRow = Math.floor(index / 2) % 2 === 0;
            const style =
              index % 2 === 0
                ? { width: isOddRow ? "47%" : "51%" }
                : { width: isOddRow ? "51%" : "47%" };
            return <DemoCard key={demo.id} data={demo} style={style} />;
          })}
        </div>
      </section>
      <section
        id="section5"
        className="lg:flex gap-x-[1vw] mt-[15%] md:mt-[5%] lg:h-[80vh] relative px-[2vh] lg:px-[1vw]"
      >
        <div className="relative w-full lg:w-[48%] h-[30vh] lg:h-auto">
          <AnimatePresence>
            <motion.img
              loading="lazy"
              key={selectedImage}
              src={selectedImage}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.8, ease: "easeInOut" }}
              alt="work show"
              className="absolute top-0 left-0 w-full h-full object-fill"
            />
          </AnimatePresence>
        </div>
        <div className="flex flex-wrap items-start lg:w-[48%] mt-[3vw] lg:mt-0 gap-x-[2vh] lg:gap-x-[1vw]">
          {buttonsContent.map((button) => (
            <ProjectShowButtons
              key={button.id}
              data={button}
              setSelectedImage={handleButtonClick}
            />
          ))}
        </div>
      </section>
      <section
        id="section6"
        className="mt-[15%] md:mt-[10%] lg:mt-[5%] text-center px-[2vh] lg:px-[1vw]"
      >
        <h1 className="mb-[13%] md:mb-[8%] lg:mb-[3%] font-bold text-[2.2vh] lg:text-[2vw] text-center bg-gradient-to-r from-transparent via-primary/10 to-transparent w-1/2 mx-auto">
          {t("landingContent.emonial_title")}
        </h1>
        <div className="flex flex-wrap justify-center gap-[2vh] lg:gap-[1vw]">
          {emonialStatus.map((emonial) => (
            <EmonialCard key={emonial.id} data={emonial} />
          ))}
        </div>
      </section>
      <LandingFooter />
    </main>
  );
}
