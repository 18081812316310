import { motion } from "framer-motion";
import { useState } from "react";
export default function ProjectShowButtons({
  data,
  setSelectedImage = () => {},
}) {
  const [focued, setFocued] = useState(false);
  return (
    <motion.button
      whileHover={{
        backgroundColor: "rgba(72, 128, 143, 0.1)",
        boxShadow: "0 0 10px rgba(72, 128, 143, 0.5)",
        opacity: 1,
      }}
      animate={
        focued
          ? {
              backgroundColor: "rgba(72, 128, 143, 0.1)",
              boxShadow: "0 0 10px rgba(72, 128, 143, 0.5)",
              opacity: 1,
            }
          : {}
      }
      whileTap={{
        backgroundColor: "rgba(72, 128, 143, 0.1)",
        boxShadow: "0 0 10px rgba(72, 128, 143, 0.5)",
        opacity: 1,
      }}
      tabIndex={0}
      className="text-start lg:text-center w-[47%] lg:w-[45%] px-[1%] lg:px-0 mb-[5%] lg:mb-[2%] opacity-50"
      onFocus={() => {
        setFocued(true);
        setSelectedImage(data.image);
      }}
      onBlur={() => {
        setFocued(false);
      }}
    >
      <h1 className="text-[1.7vh] lg:text-[1.3vw] mb-[3%] font-bold">
        {data.title}
      </h1>
      <p className="text-[1.45vh] lg:text-[1vw]">{data.content}</p>
    </motion.button>
  );
}
